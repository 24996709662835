@import "styles/core";

.content {
  font-family: "Montserrat-Bold";
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-left: size(15);
  padding-right: size(15);
  margin-top: size(150);
  margin-bottom: size(156);
  width: 100%;
  height: auto;
  background-color: $background-app;
  overflow-x: hidden;
  overflow-y: visible;
  
  @media (max-width: 568px) {
    padding-left: size(60);
    padding-right: size(60);
    margin-bottom: size(80);
    margin-top: size(100);
  }

  @media (min-width: 768px) {
    padding-left: size(60);
    padding-right: size(60);
    margin-bottom: size(172);
  }

  @media (min-width: 992px) {
    padding-left: size(110);
    padding-right: size(110);
    margin-bottom: size(111);
  }
}

.centerDiv {
  position: relative;
  height: auto;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
  z-index: 1;
}

.title {
  display: flex;
  flex-wrap: wrap;
  font-size: size(30);
  text-align: center;
  line-height: size(35);
  margin-bottom: size(0);
  padding: 0;

  @media (min-width: 768px) {
    margin-bottom: size(15);
    font-size: size(54);
    line-height: size(59);
  }

  @media (min-width: 992px) {
    font-size: size(90);
    line-height: size(95);
  }
}

.description {
  font-family: "Montserrat";
  line-break: size(24);
  line-height: size(18);
  text-align: center;
  color: #727272;
  font-size: size(13);
  margin: size(10) 0;
  padding: 0;
  font-weight: 100;

  @media (min-width: 768px) {
    font-size: size(16);
    line-height: size(20);
    margin: size(12) 0;
  }

  @media (min-width: 992px) {
    font-size: size(24);
    line-height: size(30);
  }
}

.learnMore {
  font-family: "Montserrat";
  line-break: size(24);
  line-height: size(16);
  text-align: center;
  color: #727272;
  font-size: size(10);
  margin: size(10) 0;
  padding: 0;
  font-weight: 100;
  
  .tutaj {
    background: none;
    background-color: none;
    border: none;
    pointer-events: all;
    text-decoration: underline;
    color: $font-gray;
  }
}


.arrow {
  margin-top: size(30);
  pointer-events: all;
  font-size: size(14);
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 50px;
  border: 1px solid #fff;
  box-shadow: 0 0 3px rgb(161, 161, 161);
}

.sketch {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #0000;
  z-index: 0;
}