@import "styles/core";

.container {
  font-size: size(14);

  @media (min-width: 576px) {
    font-size: size(16);
  }
}

.justifier {
  display: flex;
  justify-content: flex-start;
  @media (min-width: 474px) {
    justify-content: flex-end;
  }
}

.square {
  width: size(70);
  height: size(70);

  @media (min-width: 992px) {
    width: size(100);
    height: size(100);
  }
}

.fontBig {
  font-size: size(24);
  font-family: "Montserrat-Bold";
  @media (min-width: 474px) {
    font-size: size(34);
  }
}

.clause {
  font-family: "Montserrat";
  line-break: size(24);
  line-height: size(16);
  color: #727272;
  font-size: size(13);
  margin: size(10) 0;
  padding: 0;
  font-weight: 100;

  @media (min-width: 768px) {
    font-size: size(14);
    margin: size(12) 0;
  }

  @media (min-width: 992px) {
    font-size: size(15);
  }
}

.name {
  font-size: size(14);
  @media (min-width: 474px) {
    font-size: size(20);
  }
}

.descrTitles {
  display: flex;
  justify-content: space-between;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    margin: 0 size(6);
  }
}