@import "styles/core";

.modalBody {
  padding-left: 0;
  padding-right: 0;

  @media (min-width: 576px) {
    padding-left: size(16);
    padding-right: size(16);
  }
}

.olList {
  list-style-type: none;
  padding-left: size(20);
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    margin: 0 size(6);
  }
}
