/**
 * COLORS
 */
$black: #000000 !default;
$font-black: rgb(51, 51, 51) !default;
$font-gray: rgb(117, 117, 117) !default;
$white: #ffffff !default;
$background-app: rgb(243, 243, 243) !default;
$background-beige: #f3f3f3 !default;
$background-mintgreen: #a3edca !default;
$background-blue: #60dcfe !default;
$main-gray: #898989 !default;
$main-red: #ff6767 !default;
$main-green: #8dff8b !default;
$main-blue: #61dfff !default;
$main-yellow: #ffe858 !default;

$theme-colors: (
  "black": $black,
  "white": $white,
) !default;

$colors: map-merge((), $theme-colors) !default;

/**
 * GRID
 */
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px // inner content will be 1170px wide,,
) !default;

/**
 * FONT FAMILIES
 */
$font-family-primary: "Klavika", sans-serif;
$font-family-secondary: "Arial", sans-serif;
$font-family-sans-serif: $font-family-primary !default;
$font-family-base: $font-family-sans-serif !default;
$headings-font-family: $font-family-secondary !default;
$input-btn-font-family: $font-family-primary;

/**
 * FONT SIZES
 */
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$h1-font-size: $font-size-base * 3.75 !default;
$h2-font-size: $font-size-base * 3 !default;
$h3-font-size: $font-size-base * 2.125 !default;
$h4-font-size: $font-size-base * 1.75 !default;
$h5-font-size: $font-size-base * 1.5 !default;
$h6-font-size: $font-size-base * 1.25 !default;

/**
 * FONT WEIGHTS
 */
$font-weight-medium: 500;
$font-weight-high: 1000;
$btn-font-weight: $font-weight-medium !default;

/**
 * MISC
 */

$border-radius-lg: size(10);

/**
 * BUTTONS
 */
$input-btn-padding-y: 0.5625rem !default;

/**
 * CARDS
 */
$card-spacer-x: size(20);
$card-spacer-y: size(25);
$card-border-radius: size(10);
$card-border-width: 0;
$card-bg: $white;

/**
 * PROGRESS BAR
 */
$progress-height: size(8);
$progress-bg: $white;
$progress-border-radius: size(200);
$progress-bar-bg: $black;
