@import "styles/core";

.modalDialog {
  @media (max-width: 992px) {
    margin: 28px auto;
    max-width: 90vw;
  }
}

.modalContent {
  box-shadow: 0 0 5px rgb(65, 65, 65);;
  height: auto;
  min-height: 100%;
  border-radius: 0;
}

.header {
  border-radius: 0;
  background-color: $main-blue;
  height: 20vh;
  min-height: size(182);
  font-family: 'Montserrat-Bold';
  padding: size(50);
  @media (max-width: 768px) {
    padding: size(25);
  }
  strong {
    font-size: size(35);
    @media (max-width: 768px) {
      font-size: size(25);
    }
  }
}

.clsBtn {
  background-color: #fff;
  height: size(30);
  width: size(30);
  opacity: 100;
  border-radius: 0;
  position: absolute;
  top: 8px;
  right: 8px;
}