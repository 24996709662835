@import "styles/core";

.answerRow {
  margin: 0;
}

.answerCol {
  border: size(1) solid $main-gray;
  display: flex;
  justify-content: center;
  padding-top: size(6);
  padding-bottom: size(6);
  padding-left: size(6);
  padding-right: size(6);
  cursor: pointer;

  @media (min-width: 576px) {
    padding-left: size(12);
    padding-right: size(12);
  }
}

.answerActive {
  border: size(1) solid $main-gray;
  background: $main-blue;
  display: flex;
  justify-content: center;
  padding-top: size(6);
  padding-bottom: size(6);
  padding-left: size(6);
  padding-right: size(6);
  cursor: pointer;

  @media (min-width: 576px) {
    padding-left: size(12);
    padding-right: size(12);
  }
}

.textLeft {
  margin-top: size(6);
  display: flex;
  justify-content: flex-start;
  text-align: flex-start;
}

.textRight {
  margin-top: size(6);
  display: flex;
  justify-content: flex-end;
  text-align: flex-end;
}
