@import "styles/core";

.modalDialog {
  @media (max-width: 992px) {
    margin: 28px auto;
    max-width: 90vw;
  }
}
.header {
  border-radius: 0;
  background-color: $main-yellow;
  height: 20vh;
  min-height: size(182);
  font-family: 'Montserrat-Bold';
  padding: size(50);
  @media (max-width: 768px) {
    padding: size(25);
  }
  strong {
    font-size: size(35);
    @media (max-width: 768px) {
      font-size: size(25);
    }
  }
}
.modalContent {
  @media (max-width: 478px) {
    font-size: size(14);
  }
  box-shadow: 0 0 5px rgb(65, 65, 65);;
  height: auto;
  min-height: 100%;
  border-radius: 0;
}
.justifier {
  display: flex;
  justify-content: center;
  padding-bottom: size(16);
}

.body {
  padding: 0;
}

.formBody {
  margin: 0.3rem;
  padding: 0.3rem;
  @media (min-width: 478px) {
    margin: 1rem;
    padding: 1rem;
  }
  
  .captcha > * {
    @media (max-width: 478px) {
      transform: scale(0.7);
    }
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .formInputs {
    @media (max-width: 478px) {
      font-size: size(10);
    }
    border-radius: 0;
    border: none;
    background-color: $background-beige;
    padding: size(16);
    margin-bottom: size(16);
  }
}

.formBody > * {
  margin-bottom: 0;
}

.clsBtn {
  background-color: #fff;
  height: size(30);
  width: size(30);
  opacity: 100;
  border-radius: 0 0 0 0;
  position: absolute;
  top: 8px;
  right: 8px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    margin: 0 size(6);
  }
}