@import "styles/core";

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: auto;
  padding-top: size(20);
  padding-bottom: size(20);
  pointer-events: none;
  z-index: 3;

  @media (max-width: 568px) {
    padding: 0;
  }
}

.logo {
  width: size(130);
  height: size(60);
  pointer-events: none;
  margin-top: size(7);

  @media (max-width: 568px) {
    height: size(50);
    width: size(110);
  }

  img {
    width: 100%;
    height: auto;
  }
}

.menu {
  transform: scale(0.9);
  display: flex;
  align-items: flex-start;
}
