@import "styles/core";

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding-top: size(10);
  padding-bottom: size(10);
  background-color: $white;
}

.imgContainer {
  position: relative;
  width: 100%;
  height: size(30);
  @media (max-width: 568px) {
    height: size(20);
  }
}

.imgItem {
  background-color: $white;
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  height: 100%;
}

.imgLeft {
  background-position: 0 50%;
}

.imgLeftSmall {
  background-position: 0 50%;
}

.imgRight {
  background-position: 100% 50%;
}

.imgRightSmall {
  background-position: 100% 50%;

}
