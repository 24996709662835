@import "functions";
@import "variables";
@import "mixins";

@font-face {
  font-family: "Montserrat";
  src: url("/static/fonts/Montserrat/static/Montserrat-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("/static/fonts/Montserrat/static/Montserrat-Bold.ttf")
    format("truetype");
}

body {
  overflow-x: hidden;
  // overflow-y: hidden;
  font-family: "Montserrat", sans-serif;
  color: $font-black;
}

.btnWhite {
  font-family: 'Montserrat';
  padding: size(12);
  background-color: none;
  background: none;
  color: $font-gray;
  font-size: size(14);
  border-radius: 5px;
  border: 1px solid rgb(223, 223, 223);
}

.btnWhite:hover {
  background-color: rgb(241, 241, 241);
  color: $font-black;
  border: 1px solid #ccc;
}

.btnWhite:disabled {
  background: none;
  background-color: rgb(243, 243, 243);
  color: rgb(180, 180, 180);
}

.equalButtons {
  font-family: 'Montserrat';
  padding: size(12);
  background-color: none;
  background: none;
  color: $font-gray;
  font-size: size(14);
  border-radius: 5px;
  border: 1px solid rgb(223, 223, 223);
  width: 20%;
  @media (max-width: 960px) {
    width: 30%;
  }
  @media (max-width: 400px) {
    font-size: size(14);
    width: 40%;
  }
}

.equalButtons:hover {
  background-color: rgb(241, 241, 241);
  color: $font-black;
  border: 1px solid #ccc;
}

.equalButtons:disabled {
  background: none;
  background-color: rgb(243, 243, 243);
  color: rgb(180, 180, 180);
}