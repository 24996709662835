@import "styles/core";

.menuItem {
  font-family: "Montserrat-Bold";
  display: flex;
  align-items: center;
  padding-left: size(10);
  padding-right: size(10);
  width: auto;
  color: $font-black;
  margin: 0 size(8);
  border-radius: size(5);
  line-height: size(30);
  height: size(30);
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  background: none;
  white-space: nowrap;
  font-size: size(14);
  pointer-events: all;
}

.homepage {
  margin: 0 size(14);
  padding: size(10) size(70);
  background-color: $white;
  color: $font-black;
  border: none;
  border-radius: size(40);
  text-transform: uppercase;
  font-size: size(20);
  pointer-events: all;
  cursor: pointer;
}

.start:hover {
  color: #23527c;
}

.contact:hover {
  color: #23527c;
}

.about:hover {
  color: #23527c;
}
